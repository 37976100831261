@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: 'Inter', system-ui, sans-serif;
  }
}

/* Custom Styles */
.opportunity-card {
  @apply bg-white border border-gray-300 rounded-lg p-6 hover:shadow-lg transition duration-300 ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.opportunity-card h3 {
  @apply font-semibold text-lg mb-4 text-blue-700;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  min-height: 60px;
}

.search-container {
  @apply relative max-w-4xl mx-auto;
}

.search-container::before {
  content: '';
  @apply absolute inset-0 bg-gradient-to-r from-blue-100 via-purple-100 to-pink-100 transform -skew-y-2 rounded-3xl shadow-lg;
}

.search-input-wrapper {
  @apply relative bg-white bg-opacity-90 backdrop-filter backdrop-blur-sm rounded-3xl p-8 shadow-sm;
}

.custom-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: #4b5563 #e5e7eb;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 8px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #c5c5c5;
  border-radius: 10px;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #a8a8a8;
}

.fade-in {
  animation: fadeIn 0.4s ease-in-out;
}

@keyframes fadeIn {
  from { opacity: 0; transform: translateY(5px); }
  to { opacity: 1; transform: translateY(0); }
}

@keyframes gradientBackground {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}

.modal-backdrop {
  backdrop-filter: blur(5px);
}

.modal-content {
  background: linear-gradient(-45deg, #f0f5ff, #e6f0ff, #f0f7ff, #e8f4ff);
  background-size: 400% 400%;
  animation: gradientBackground 15s ease infinite, modalFadeIn 0.3s ease-out;
}

.results-container {
  @apply grid gap-6;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  max-width: 1400px;
  margin: 0 auto;
}

.btn-primary {
  @apply bg-indigo-600 hover:bg-indigo-700 text-white font-semibold py-2 px-4 rounded-lg transition duration-300;
}

.btn-secondary {
  @apply bg-gray-200 hover:bg-gray-300 text-gray-800 font-semibold py-2 px-4 rounded-lg transition duration-300;
}

/* Modal Styles */
.modal {
  @apply fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50;
}

.modal-container {
  @apply bg-white rounded-xl p-8 max-w-lg w-full mx-4 shadow-xl transform transition-all;
  animation: modalFadeIn 0.3s ease-out;
}

@keyframes modalFadeIn {
  from { opacity: 0; transform: translateY(-20px); }
  to { opacity: 1; transform: translateY(0); }
}

/* Advanced Filters Styles */
.filters-container {
  @apply max-w-4xl mx-auto mb-8 bg-white rounded-xl p-6 shadow-sm;
  animation: fadeIn 0.4s ease-in-out;
}

/* Import Modal Styles */
.import-form {
  @apply space-y-6;
}

.import-input {
  @apply w-full p-3 border rounded-lg focus:ring-2 focus:ring-blue-300;
}

.import-button {
  @apply w-full bg-blue-500 text-white py-3 rounded-lg hover:bg-blue-600 transition duration-150 ease-in-out;
}
